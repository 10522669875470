import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  NavbarToggler
} from "reactstrap";

class NavBar extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    return (
      <Navbar
        style={{ maxWidth: "800px", margin: "auto" }}
        color="light"
        light
        expand="md"
      >
        <NavbarBrand tag={Link} to="/">
          DiaryJoy
        </NavbarBrand>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <NavLink tag={Link} to="/">
                Write
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to="/entries">
                Entries
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to="/settings">
                Settings
              </NavLink>
            </NavItem>
          </Nav>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink tag="a" href="#" onClick={this.props.signInOrOut}>
                {this.props.signedIn ? "Sign Out" : "Sign In"}
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}

export default NavBar;
