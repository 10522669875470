import React, { Component } from 'react';
import {getEntries} from "../util/db";
import { ListGroup, ListGroupItem } from 'reactstrap';
import {Link} from "react-router-dom";
var moment = require("moment");

class Entries extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      entriesData: []
    }
  }
  

  componentDidMount() {
    if (this.props.signedIn) {
      this.loadEntries();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.signedIn != this.props.signedIn) {
      this.loadEntries();
    }
  }

  loadEntries = async () => {
    let entriesData = await getEntries(this.props.uid);
    console.log(entriesData);
    this.setState({
      entriesData
    });
  }

  getEntryList() {
    if (!this.props.signedIn) {
      return "Please sign in to see your entries";
    }

    return this.state.entriesData.map(entry => {
      let createDate = entry.createDate.toDate();
      let dateString = moment(createDate).format("dddd, MMMM Do YYYY, h:mm:ss a")
      let content = entry.content;
      content = content.replace(/<(?:.|\n)*?>/gm, '');
      content = content.substring(0, 40);

      return (
        <ListGroupItem key={entry.id} tag={Link} to={`/view/${entry.id}`}>{dateString}-{content}...</ListGroupItem>
      )
    })
  }

  render() {
    return (
      <div>
        <ListGroup>
          {this.getEntryList()}
        </ListGroup>
      </div>
    );
  }
}

export default Entries;