import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./App.css";
import Write from "./screens/Write";
import Entries from "./screens/Entries";
import NavBar from "./components/NavBar";

import firebase from "./util/firebase";
import IdleTimer from "react-idle-timer";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uid: "",
      signedIn: false
    };
  }

  signInOrOut = () => {
    var user = firebase.auth().currentUser;

    if (user) {
      this.signOut();
    } else {
      this.signIn();
    }
  };

  signOut() {
    firebase
      .auth()
      .signOut()
      .then(function() {
        // Sign-out successful.
      })
      .catch(function(error) {
        // An error happened.
      });
  }

  signIn() {
    var provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithRedirect(provider);
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        console.log("Signed in", user);
        this.setState({
          uid: user.uid,
          signedIn: true
        });
      } else {
        this.setState({
          signedIn: false
        });
      }
    });
  }

  onIdle = () => {
    this.signOut();
  }

  render() {
    return (
      <Router>
        <IdleTimer
          element={document}
          onIdle={this.onIdle}
          timeout={1000 * 60}
        />
        <NavBar signedIn={this.state.signedIn} signInOrOut={this.signInOrOut} />
        <div className="App">
          <Route
            path="/"
            exact
            render={props => (
              <Write
                {...props}
                uid={this.state.uid}
                signIn={this.signIn}
                signOut={this.signOut}
                signedIn={this.state.signedIn}
              />
            )}
          />
          <Route
            path="/view/:entryKey"
            exact
            render={props => (
              <Write
                {...props}
                uid={this.state.uid}
                signedIn={this.state.signedIn}
              />
            )}
          />
          <Route
            path="/entries"
            exact
            render={props => (
              <Entries
                {...props}
                uid={this.state.uid}
                signedIn={this.state.signedIn}
              />
            )}
          />
        </div>
      </Router>
    );
  }
}

export default App;
