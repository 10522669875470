// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import * as firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/firestore";

// TODO: Replace the following with your app's Firebase project configuration
var config = {
  apiKey: "AIzaSyDdQvYZLwN8bKCJ30Frzhbou3q2EwoTsZQ",
  authDomain: "diaryjoy-edda4.firebaseapp.com",
  databaseURL: "https://diaryjoy-edda4.firebaseio.com",
  projectId: "diaryjoy-edda4",
  storageBucket: "diaryjoy-edda4.appspot.com",
  messagingSenderId: "56366224781"
};
 
 // Initialize Firebase
 firebase.initializeApp(config);

 export default firebase;