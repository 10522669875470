import React, { Component } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Alert } from 'reactstrap';
import { getDocRef, getLatestDoc } from "../util/db";
var moment = require("moment");

const WELCOME_TEXT =
  "<h1>Welcome!</h1><p><strong>DiaryJoy </strong>is a dead simple online diary. Start writing right away in this box!</p><p><br></p><h2>Features</h2><ul><li>Free, forever!</li><li>No ads</li><li>Rich formatting</li><li>Unlimited storage</li><li>Autosave</li></ul><p><br></p><h2>Privacy</h2><ul><li>Encrypted storage</li><li>Auto sign out after idle activity</li></ul>";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = { text: "" }; // You can also pass a Quill Delta here
    this.handleChange = this.handleChange.bind(this);
    this.quillRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.signedIn) {
      console.log("Signed in!");
      this.getEntryContent();
    } else {
      this.setState({
        text: WELCOME_TEXT
      });
      this.entryKey = "";
      this.quillRef.current.getEditor().history.clear();
    }
  }

  // TODO do not let anything happen until uid is set
  componentDidUpdate(prevProps) {
    if (prevProps.signedIn != this.props.signedIn) {

      // User signed out, so clear everything!
      if (!this.props.signedIn) {
        this.setState({
          text: WELCOME_TEXT
        });
        this.entryKey = "";
        this.quillRef.current.getEditor().history.clear();

        return;
      } else {
        this.getEntryContent();
      }
    }
  }

  async displayEntry() {
    let entryDoc = await getDocRef(this.props.uid, this.entryKey).get();
    if (!entryDoc.exists) {
      this.props.history.replace("/");
      return;
    }

    let entryData = entryDoc.data();
    this.setState({
      text: entryData.content
    });
  }

  async getEntryContent() {
    // If entry key is already loaded, just display it
    if (this.props.match.params.entryKey) {
      this.entryKey = this.props.match.params.entryKey;
      console.log("Already loaded", this.entryKey);
      this.displayEntry();
      return;
    }
    // Get last entry in Firestore
    let querySnap = await getLatestDoc(this.props.uid);

    // If it doesn't exist, OR it's older than N hours, then create new
    let lastDate;
    let entryData;

    querySnap.forEach(doc => {
      entryData = doc.data();
      this.entryKey = doc.id;
      lastDate = entryData.createDate.toDate();
    });

    // Nothing was found
    if (!lastDate) {
      this.createNewEntry();
      return;
    }

    let now = moment(new Date());
    let then = moment(lastDate);

    var duration = moment.duration(now.diff(then));
    var hours = duration.asHours();
    console.log(hours);

    if (hours > 16) {
      this.createNewEntry();
    } else {
      this.setState({
        text: entryData.content
      });
    }

    return;
  }

  createNewEntry() {
    this.entryKey = Date.now() + "";
    this.firstChange = true;

    // Might be existing data from signed out state
    // However, if the existing data is just the intro text, then clear it
    if (this.state.text === WELCOME_TEXT) {
      this.setState({
        text: ""
      });
    }
  }

  handleChange(value) {
    this.setState({ text: value});
    this.saveToFirestore(value);
  }

  saveToFirestore(value) {
    if (this.saveTimer) {
      clearTimeout(this.saveTimer);
    }

    this.saveTimer = setTimeout(() => {
      console.log("Saving...");
      this.saveNow(value);
    }, 5000);
  }

  saveNow(value) {
    if (!this.props.signedIn) {
      return;
    }
    let docRef = getDocRef(this.props.uid, this.entryKey);

    let newData = {
      content: value
    };

    if (this.firstChange) {
      newData.createDate = new Date();
    }

    docRef.set(newData, { merge: true });
    this.firstChange = false;

    this.setState({
      saveDate: new Date()
    })
  }

  componentWillUnmount() {
    if (this.saveTimer) {
      clearTimeout(this.saveTimer);
    }
  }

  getSaveDate() {
    return moment(this.state.saveDate).format("MMMM Do YYYY, h:mm:ss a")
  }

  getSignInAlert() {
    if (!this.props.signedIn) {
      return(
        <Alert color="warning">
          Please <a href="#" onClick={this.props.signIn}>sign in</a> to save your entry!
        </Alert>
      );
    }
  }

  render() {
    return (
      <div>
        {this.getSignInAlert()}
        <ReactQuill
          value={this.state.text}
          placeholder={"New entry...Start writing here!"}
          onChange={this.handleChange}
          ref={this.quillRef}
        />
        {this.props.signedIn && <div style={{fontSize: "12px"}}>Last saved: {this.getSaveDate()} <br /> Signing out after 1 min of inactivity</div>}
      </div>
    );
  }
}

export default Home;
