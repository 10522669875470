import firebase from "./firebase";

const USERS_COLLECTION = "users";
const ENTRIES_COLLECTION = "entries";

function getEntryCollection(uid) {
  return firebase
    .firestore()
    .collection(USERS_COLLECTION)
    .doc(uid)
    .collection(ENTRIES_COLLECTION);
}

export function getDocRef(uid, entryKey) {
  let docRef = getEntryCollection(uid).doc(entryKey);
  return docRef;
}

export function getLatestDoc(uid) {
  return getEntryCollection(uid)
    .orderBy("createDate", "desc")
    .limit(1)
    .get();
}

export async function getEntries(uid) {
  let result = [];
  await getEntryCollection(uid).get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        let data = doc.data();
        data.id = doc.id;
        result.push(data);
      });
    });
  
  return result;
}